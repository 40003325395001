<template>
  <div>
    <HeadImage
      :src="product['image_url']"
      contain
      :action="recentAction"
      :map-zone="product['map_zone']" />

    <div class="c-section c-section--mb24">
      <h1 class="c-title4">
        {{ product['name'] }}
      </h1>

      <p class="c-lead">
        {{ product['description'] }}
      </p>

      <ul v-if="event.title || event.at" class="c-list c-list--grey01">
        <li v-if="event.at" class="c-list__item">
          <Icon name="clock" />
          <span>
            {{ $utilities.localizeDate(event.at) }}
          </span>
        </li>

        <li v-if="event.title" class="c-list__item">
          <Icon name="pin" />
          <span>
            {{ event.title }}
          </span>
        </li>
      </ul>
    </div>

    <template v-if="product['baristas'].length">
      <div class="c-section c-section--mb32">
        <h3 class="c-title6">{{ WORDS.PRODUCT.SUPPLIER }}</h3>

        <ProCard
          :content="product['baristas']"
          :description="product['barista_message']" />
      </div>

      <!-- #UCC_APP-1026 -->
      <!-- <div
        v-if="product.ingredients.length"
        class="pro-coffee-detail-ingredients c-section c-section--mb24">
        <h3 class="c-title6">{{ WORDS.PRODUCT.COFFEE_BEANS }}</h3>

        <CoffeeCard
          v-for="(coffee, i) in ingredients.product"
          :key="i"
          :href="$customUrlScheme.page('ec', `url=${coffee['ec_link_url']}`)"
          :src="coffee['image_url']"
          :title="coffee.name" />

        <ul class="c-list2">
          <li
            v-for="(coffee, i) in ingredients.free"
            :key="i"
            class="c-list2__item">
            <Icon name="pack" />
            <span>
              {{ coffee.name }}
            </span>
          </li>
        </ul>
      </div> -->
    </template>

    <RateButtons
      v-if="product['map_position']"
      :impression-count="impressionCount"
      @click="createImpression($event)" />

    <div v-else class="pro-coffee-detail-impression-button">
      <div class="c-section">
        <Button style-type="secondary" fluid @click="createImpression(null)">
          {{ WORDS.PRODUCT.SEND_BUTTON_IMPRESSION }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    RateButtons: () => import('@/components/coffeeDetail/RateButtons.vue')
  },

  props: {
    product: {
      type: Object,
      required: true
    },

    event: {
      type: Object,
      default: () => ({
        id: null,
        title: null,
        at: null
      })
    }
  },

  setup: (props, context) => {
    const impressionCount = computed(() => {
      return {
        like: props?.product['impression_count'].is_like || 0,
        hate: props?.product['impression_count'].is_hate || 0
      };
    });

    /**
     * 使用しているコーヒー豆
     */
    const ingredients = computed(() => {
      // 統合製品
      const product = props.product?.ingredients.filter((item) => item.id);
      // 自由入力（=idが無いもの）
      const free = props.product?.ingredients.filter((item) => !item.id);
      return {
        product,
        free
      };
    });

    const recentAction = computed(() => {
      if (!props.product?.impressions?.[0]) return;

      const at = props.product?.impressions[0].created_at;
      const isFavorable = props.product?.impressions[0].is_favorable;
      const type =
        isFavorable === null ? 'memo' : isFavorable ? 'like' : 'hate';

      return { type, at };
    });

    const createImpression = (isFavorable) => {
      const payload = {
        is_favorable: isFavorable,
        memo: {
          aroma: null,
          bitterness: null,
          sourness: null,
          sweetness: null,
          richness: null,
          comment: null
        }
      };
      context.emit('create:impression', payload);
    };

    return {
      WORDS,
      impressionCount,
      ingredients,
      recentAction,
      createImpression
    };
  }
});
</script>

<style lang="scss" scoped>
.pro-coffee-detail-ingredients::v-deep {
  .c-coffeeCard {
    margin-bottom: 16px;
  }
}

.pro-coffee-detail-impression-button::v-deep {
  .c-coffeeCard {
    margin-bottom: 16px;
  }
}
</style>
