var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeadImage", {
        attrs: {
          src: _vm.product["image_url"],
          contain: "",
          action: _vm.recentAction,
          "map-zone": _vm.product["map_zone"],
        },
      }),
      _c("div", { staticClass: "c-section c-section--mb24" }, [
        _c("h1", { staticClass: "c-title4" }, [
          _vm._v(" " + _vm._s(_vm.product["name"]) + " "),
        ]),
        _c("p", { staticClass: "c-lead" }, [
          _vm._v(" " + _vm._s(_vm.product["description"]) + " "),
        ]),
        _vm.event.title || _vm.event.at
          ? _c("ul", { staticClass: "c-list c-list--grey01" }, [
              _vm.event.at
                ? _c(
                    "li",
                    { staticClass: "c-list__item" },
                    [
                      _c("Icon", { attrs: { name: "clock" } }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$utilities.localizeDate(_vm.event.at)) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.event.title
                ? _c(
                    "li",
                    { staticClass: "c-list__item" },
                    [
                      _c("Icon", { attrs: { name: "pin" } }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.event.title) + " ")]),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm.product["baristas"].length
        ? [
            _c(
              "div",
              { staticClass: "c-section c-section--mb32" },
              [
                _c("h3", { staticClass: "c-title6" }, [
                  _vm._v(_vm._s(_vm.WORDS.PRODUCT.SUPPLIER)),
                ]),
                _c("ProCard", {
                  attrs: {
                    content: _vm.product["baristas"],
                    description: _vm.product["barista_message"],
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.product["map_position"]
        ? _c("RateButtons", {
            attrs: { "impression-count": _vm.impressionCount },
            on: {
              click: function ($event) {
                return _vm.createImpression($event)
              },
            },
          })
        : _c("div", { staticClass: "pro-coffee-detail-impression-button" }, [
            _c(
              "div",
              { staticClass: "c-section" },
              [
                _c(
                  "Button",
                  {
                    attrs: { "style-type": "secondary", fluid: "" },
                    on: {
                      click: function ($event) {
                        return _vm.createImpression(null)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.PRODUCT.SEND_BUTTON_IMPRESSION) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }